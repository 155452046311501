import axios from "axios";
import {
  CLOSE_COMMISSION_DIALOG,
  CREATE_NEW_COMMISSION,
  DELETE_COMMISSION,
  EDIT_COMMISSION,
  GET_COMMISSION,
} from "./Commission.type";
import { apiInstanceFetch } from "../../util/api";
import { setToast } from "../../util/toast";

export const getCommission = () => (dispatch) => {
  apiInstanceFetch
    .get(`commission/get?type=1`)
    .then((res) => {
      if (res.status) {
        dispatch({ type: GET_COMMISSION, payload: res });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};

export const createNewCommission = (data) => (dispatch) => {
  axios
    .post(`commission/store`, data)
    .then((res) => {
        debugger
      if (res.data.status) {
        setToast("success", "Commission created successfully!");
        dispatch({ type: CLOSE_COMMISSION_DIALOG });
        dispatch({
          type: CREATE_NEW_COMMISSION,
          payload: res.data?.commission,
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const editCommission = (commissionId, data) => (dispatch) => {
  axios
    .patch(`commission/update?commissionRateId=${commissionId}`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Commission updated successfully!");
        dispatch({ type: CLOSE_COMMISSION_DIALOG });
        dispatch({
          type: EDIT_COMMISSION,
          payload: { data: res.data?.commission, id: commissionId },
        });
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const deleteCommission = (commissionId) => (dispatch) => {
  axios
    .delete(`commission/delete?commissionRateId=${commissionId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_COMMISSION, payload: commissionId });
        setToast("success", "Commission Deleted successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
