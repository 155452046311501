// GET_USER
export const GET_USER = "GET_USER";

//  get user profile
export const GET_USER_PROFILE = "GET_USER_PROFILE";

// user block
export const GET_USER_BLOCK = "GET_USER_BLOCK";

// get user history 
export const GET_USER_HISTORY = "GET_USER_HISTORY";

// UPDATE_HOST_COIN 
export const UPDATE_USER_COIN = "UPDATE_USER_COIN";
