import React, { useEffect, useState } from "react";
import {
  getUserProfile,
  blockUser,
  updateHostCoin,
} from "../store/user/user.action";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { setToast } from "../util/toast";
import girlImg from "../assets/img/female.png";
import { getAgencyProfile } from "../store/Agency/agency.action";

const AgencyProfile = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [imagePath, setImagePath] = useState();

  let id = location.state._id;

  console.log("id", id);
  console.log("imagePath", imagePath);

  const { userProfile } = useSelector((state) => state.user);
  const { agencyProfile } = useSelector((state) => state.agency);

  console.log("agencyProfile", agencyProfile);

  useEffect(() => {
    dispatch(getAgencyProfile(id));
  }, [dispatch]);

  useEffect(() => {
    console.log("Agency image path:", agencyProfile?.image);
    setImagePath(agencyProfile?.image);
  }, [agencyProfile]);

  const history = useHistory();
  const handlePreviousPage = () => {
    history.goBack();
  };
  const handleClick = (id) => {
    props.blockUser(id);
  };

  const handleOpenImage = (url) => {
    window.open(url, "_blank");
  };

  const handleHistory = () => {
    history.push({
      pathname: "/admin/user/history",
      state: { userId: id },
    });
  };

  const handleSave = (val) => {
    console.log("val", val);
    if (val < 0) {
      setToast("error", "Invalid Coin");
    } else {
      const coinValid = isNumeric(val);
      if (!coinValid) {
        setToast("error", "Invalid Coin");
      } else {
        props.updateHostCoin(val, userProfile._id);
      }
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  return (
    <>
      <div className="row justify-content-between">
        <h4 className="ml-3">
          {capitalizeFirstLetter(agencyProfile?.name || "")} Agency Profile
        </h4>
        <div className="userBtn">
          <button
            className="btn btn-primary"
            style={{ marginRight: "15px" }}
            onClick={handlePreviousPage}
          >
            <i className="fa-solid fa-angles-left"></i>
          </button>
        </div>
      </div>

      <div className="card mt-4">
        <div className="card-body">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-3 col-xxl-3 col-xl-3">
              <img
                src={imagePath}
                draggable="false"
                className="mx-auto image"
                style={{
                  width: "260px",
                  height: "260px",
                  objectFit: "cover",
                  display: "block",
                  borderRadius: "50%",
                }}
                alt=""
                onClick={() => handleOpenImage(agencyProfile?.image)}
                onError={(e) => {
                  e.target.src = girlImg; // Fallback to default image
                }}
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-9 col-xxl-9 col-xl-9">
              <div className="row">
                <table className="w-100 userTable">
                  <tbody>
                    <tr>
                      <td width="150px" className="py-3 text-profile">
                        Name
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">
                        {agencyProfile?.name ? agencyProfile?.name : ""}
                      </td>
                    </tr>
                    <tr>
                      <td width="150px" className="py-3 text-profile">
                        Mobile No
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">
                        {agencyProfile?.mobile ? agencyProfile?.mobile : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td width="150px" className="py-3 text-profile">
                        AgencyCode
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">
                        {agencyProfile?.agencyCode ? agencyProfile?.agencyCode : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td width="150px" className="py-3 text-profile">
                        UniqueID
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">
                        {agencyProfile?.uniqueID ? agencyProfile?.uniqueID : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td width="150px" className="py-3 text-profile">
                        Lifetime Earning
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">
                        {agencyProfile?.lifeTimeIncome ? agencyProfile?.lifeTimeIncome : "-"}
                      </td>
                    </tr>
                    <tr>
                      <td width="90px" className="py-3 text-profile">
                        CreatedAt
                      </td>
                      <td width="50px" className="text-white">:</td>
                      <td className="text-white">{agencyProfile?.createdAt?.split("T")[0]}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getUserProfile, blockUser, updateHostCoin })(AgencyProfile);
