// get_ sticker

export const GET_STICKER = "GET_STICKER";

// banner dialog open
export const OPEN_STICKER = "OPEN_STICKER";

// close sticker
export const CLOSE_STICKER = "CLOSE_STICKER";

//  create sticker
export const CREATE_STICKER = "CREATE_STICKER";

// update banner
export const UPDATE_STICKER = "UPDATE_STICKER";

// delete sticker
export const DELETE_STICKER = "DELETE_STICKER";