import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { OPEN_DIALOGUE_SETTLEMENT } from "../../../store/AgencySettlement/AgencySettlement.type";
import {
  getAgencySettlement,
  paidSettlement,
} from "../../../store/AgencySettlement/AgencySettlement.action";
import { Tooltip } from "@mui/material";
import SettlementActionDialogue from "./SettlementActionDialog";

const SettlementPending = (props) => {
  const { agencySettlement, dialog: open } = useSelector(
    (state) => state.settlement
  );
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("ALL");
  const [endDate, setEndDate] = useState("ALL");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [search, setSearch] = useState("");

  console.log("data", data);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    const type = 1;
    const payload = { startDate, endDate, type };
    dispatch(getAgencySettlement(payload));
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    setData(agencySettlement);
  }, [agencySettlement]);

  const handleFilterData = (filteredData) => {
    if (typeof filteredData === "string") {
      setSearch(filteredData);
    } else {
      setData(filteredData);
    }
  };

  const handlePayment = (id) => {
    dispatch(paidSettlement(id));
  };

  const handleSearch = (e) => {
    const value = e.target.value?.toUpperCase();
    if (value) {
      const data = agencySettlement.filter((data) => {
        return (
          data?.name?.toUpperCase()?.indexOf(value) > -1 ||
          data?.totalCoinEarned?.toString()?.indexOf(value) > -1 ||
          data?.agencyCode?.toUpperCase()?.indexOf(value) > -1 ||
          data?.totalCoin?.toString()?.indexOf(value) > -1
        );
      });
      setData(data);
    } else {
      setData(agencySettlement);
    }
  };

  return (
    <>
      <div className="page-title">
        <div className="row py-2">
          <div class="col-3">
            <h4 className="hostTitle"> Agency Pending Settlement</h4>
          </div>
          <div class="col-9">
            <div class="breadcrumb-four float-right">
              <ul class="breadcrumb">
                <li>
                  <Link to="/admin/dashboard">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-home"
                    >
                      <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                      <polyline points="9 22 9 12 15 12 15 22"></polyline>
                    </svg>
                  </Link>
                </li>

                <li class="active">
                  <a href={() => false}>Agency Pending Settlement</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left"></div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0">
                  <form action="">
                    <div className="input-group mb-3 border rounded-pill">
                      <input
                        type="search"
                        id="searchBar"
                        autoComplete="off"
                        placeholder="What're you searching for?"
                        aria-describedby="button-addon4"
                        className="form-control bg-none border-0 rounded-pill searchBar"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <div className="input-group-prepend border-0">
                        <div
                          id="button-addon4"
                          className="btn text-danger"
                          onClick={handleSearch}
                        >
                          <i className="fas fa-search mt-2"></i>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-body card-overflow table-responsive">
              <table className="table table-striped">
                <thead className="text-center">
                  <tr>
                    <th>No.</th>
                    <th>Name</th>
                    <th> Date</th>
                    <th>Host Coin</th>

                    <th>Coin</th>
                    <th>Coin (%)</th>
                    <th>Bonus/Penalty</th>
                    <th>Total Coin</th>
                    {/* <th>Total Amount</th> */}
                    <th>Amount ($)</th>
                    <th>Pay</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {data?.length > 0 ? (
                    data.map((value, agencyIndex) => (
                      <tr key={`${agencyIndex}-${agencyIndex}`}>
                        <td>
                          {(currentPage - 1) * rowsPerPage + agencyIndex + 1}
                        </td>
                        <td>{value?.agency?.name}</td>
                        <td>{`${value?.startDate} - ${value?.endDate}`}</td>
                        <td>{value?.hostCoin}</td>
                        <td>{value?.commissionCoinEarned}</td>
                        <td>{value?.agencyCommissionPercentage}</td>
                        <td>{value?.bonusPenalty}</td>
                        {/* <td>{value?.totalCoinEarned || 0}</td> */}
                        <td>{value?.totalCoinEarned}</td>
                        <td>{value?.dollar}</td>
                        <td>
                          <Tooltip title="Pay">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() => handlePayment(value?._id)}
                            >
                              Pay
                            </button>
                          </Tooltip>
                        </td>
                        <td>
                          <Tooltip title="Action">
                            <button
                              type="button"
                              className="btn btn-sm btn-info"
                              onClick={() =>
                                dispatch({
                                  type: OPEN_DIALOGUE_SETTLEMENT,
                                  payload: {
                                    data: value,
                                    id: value._id,
                                    type: "settlementActionDialogue",
                                  },
                                })
                              }
                            >
                              Action
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <SettlementActionDialogue />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getAgencySettlement, paidSettlement })(
  SettlementPending
);
