import React, { useEffect, useState } from "react";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//action
import { getCommission, deleteCommission } from "../../store/Commission/Commission.action";

//config

//routing

//MUI
import { Link, TablePagination, Tooltip } from "@mui/material";

// dialog
import CommissionDialog from "../Dialog/CommissionDialog";

//sweet alert
import { alert, warning } from "../../util/Alert";

import $ from "jquery";
//image
import noImage from "../../assets/img/maleImage.png";
import TablePaginationActions from "../../util/Pagination";

import { OPEN_COMMISSION_DIALOG } from "../../store/Commission/Commission.type";
// import arraySort from "array-sort";


const CommissionTable = (props) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [coinSort, setCoinSort] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const commission = useSelector((state) => state?.commision?.commission);
  useEffect(() => {
    dispatch(getCommission());
  }, []);

  useEffect(() => {
    setData(commission)
  },[commission])


  console.log('commission', commission)
  console.log('data', data)

//   useEffect(() => {
//     setData(commission);
//   }, [commission]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  $(document).ready(function () {
    $("img").bind("error", function () {
      $(this).attr("src", noImage);
    });
  });

  const handleSearch = (e) => {
    const value = e.target.value.trim().toUpperCase();
    if (value) {
      const data = commission?.filter((data) => {
        return data?.URL?.toUpperCase()?.indexOf(value) > -1;
      });
      setData(data);
    } else {
      return setData(commission);
    }
  };

  const handleOpen = () => {
    dispatch({ type: OPEN_COMMISSION_DIALOG });
  };

  const handleDelete = (commissionId) => {
    const data = warning();
    data
      .then((isDeleted) => {
        if (isDeleted) {
         dispatch(deleteCommission(commissionId));
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (data) => {
    dispatch({ type: OPEN_COMMISSION_DIALOG, payload: data });
  };

  const handleCoinSort = () => {
    setCoinSort(!coinSort);
    // arraySort(data, "upperCoin", { reverse: coinSort });
  };
  return (
    <>
      <div class="row">
        <div class="col-2">
          <h4 className="hostTitle"> Commission </h4>
        </div>
        <div class="col-10">
          <div class="breadcrumb-four float-right">
            <ul class="breadcrumb">
              <li>
                <Link to="/admin/dashboard">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-home"
                  >
                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                  </svg>
                </Link>
              </li>

              <li class="active">
                <a href={() => false}>Commision </a>
              </li>
            </ul>
          </div>
      </div>
        <div class="col">
          <div class="card">
            <div className="card-header pb-0">
              <div className="row my-3">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-8 float-left">
                <div class="col-xl-8 col-md-8 col-sm-12 col-12 mb-4 pl-0">
                  <button
                    class="btn text-white btn-danger text-center"
                    onClick={handleOpen}
                  >
                    <i class="fa fa-plus pr-1" aria-hidden="true"></i> Add
                  </button>
                </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 float-right mt-3 mt-lg-0 mt-xl-0"></div>
              </div>
            </div>
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <table class="table table-striped custom-table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th style={{ cursor: "pointer" }}>
                      Upper Coin 
                    </th>
                    <th>Percentage</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length > 0 ? (
                    data?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.upperCoin}</td>
                          <td className="text-success">
                            {data.amountPercentage + "%"}
                          </td>
                          <td>
                            <Tooltip title="Edit">
                              <button
                                type="button"
                                className="btn btn-sm btn-info"
                                onClick={() => handleEdit(data)}
                              >
                                <i className="fa fa-edit fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                          <td>
                            <Tooltip title="Delete">
                              <button
                                type="button"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleDelete(data?._id)}
                              >
                                <i className="fas fa-trash-alt fa-lg"></i>
                              </button>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="12" align="center">
                        Nothing to show!!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <TablePagination
                id="pagination"
                component="div"
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  100,
                  { label: "All", value: -1 },
                ]}
                count={data?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                classes="menuItem"
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </div>
          </div>
        </div>
      </div>
      <CommissionDialog />
    </>
  );
};

export default connect(null, { getCommission, deleteCommission })(
  CommissionTable
);
